import { ofetch } from "ofetch";
import camelcaseKeys from "camelcase-keys";
import { getPublicEnv } from "./helpers";

const appEnv = getPublicEnv("REMIX_APP_ENVIRONMENT");

export const apiClient = ofetch.create({
  retry: appEnv === "development" ? 0 : 3,
  retryDelay: 500,
  async onRequest({ options }) {
    const baseURL = getPublicEnv("POSTCO_APP_SERVER_URL");
    options.baseURL = baseURL;
  },
  async onRequestError({ error, request }) {
    console.log("[fetch request error]", request, error);
  },
  parseResponse: (response) => {
    try {
      return camelcaseKeys(JSON.parse(response), { deep: true });
    } catch (SyntaxError) {
      return response;
    }
  },
});
